import { useEffect } from "react";
import type { NextPage } from "next";
// import { authStore } from "@state/store";

// import Authentication from "./authentication";
// import Meetings from "./meetings";

const HomePage: NextPage = () => {
  // const { isSignedIn } = authStore();

  useEffect(() => {
    // redirect / to the portal
    window.location.replace("/portal");
  }, []);

  return null;
  // return <>{isSignedIn ? <Meetings /> : <Authentication />}</>;
};

export default HomePage;
